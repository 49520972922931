import React from "react";
import Layout from "../../../other/layout";
import GalarieDeLogos from "../../../components/AC-StaticPages/Demarrez/ConceptionDeLogo/GalarieDeLogos/GalarieDeLogos";

import SEO from "../../../other/seo";

export default function galarieDeLogos() {
  return (
    <Layout>
      <SEO title="Galarie De Logos" />
      <GalarieDeLogos />
    </Layout>
  );
}
