import React from "react";
import { Link } from "gatsby";
import "../../../../../assets/css/global.css";
import styles from "./styles.module.css";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Button from "../../../components/Button";
import logo1 from "../../../../../assets/img/logogallery/logo-featured-01.jpg";
import logo2 from "../../../../../assets/img/logogallery/logo-featured-02.jpg";
import logo3 from "../../../../../assets/img/logogallery/logo-featured-03.jpg";
import logo4 from "../../../../../assets/img/logogallery/logo-featured-04.jpg";
import logo5 from "../../../../../assets/img/logogallery/logo-featured-05.jpg";
import logo6 from "../../../../../assets/img/logogallery/logo-featured-06.jpg";
import logo7 from "../../../../../assets/img/logogallery/logo-featured-07.jpg";
import logo8 from "../../../../../assets/img/logogallery/logo-featured-08.jpg";
import logo9 from "../../../../../assets/img/logogallery/logo-featured-09.jpg";
import logo10 from "../../../../../assets/img/logogallery/logo-featured-10.jpg";
import logo11 from "../../../../../assets/img/logogallery/logo-featured-11.jpg";
import logo12 from "../../../../../assets/img/logogallery/logo-featured-12.jpg";
import logo13 from "../../../../../assets/img/logogallery/logo-featured-13.jpg";
import logo14 from "../../../../../assets/img/logogallery/logo-featured-14.jpg";
import logo15 from "../../../../../assets/img/logogallery/logo-featured-15.jpg";
import logo16 from "../../../../../assets/img/logogallery/logo-featured-16.jpg";
import logo17 from "../../../../../assets/img/logogallery/logo-featured-17.jpg";
import logo18 from "../../../../../assets/img/logogallery/logo-featured-18.jpg";
import logo19 from "../../../../../assets/img/logogallery/logo-featured-19.jpg";
import logo20 from "../../../../../assets/img/logogallery/logo-featured-20.jpg";
import logo21 from "../../../../../assets/img/logogallery/logo-featured-21.jpg";
import logo22 from "../../../../../assets/img/logogallery/logo-featured-22.jpg";
import logo23 from "../../../../../assets/img/logogallery/logo-featured-23.jpg";
import logo24 from "../../../../../assets/img/logogallery/logo-featured-24.jpg";
import logo25 from "../../../../../assets/img/logogallery/logo-featured-25.jpg";
import logo26 from "../../../../../assets/img/logogallery/logo-featured-26.jpg";
import logo27 from "../../../../../assets/img/logogallery/logo-featured-27.jpg";
import logo28 from "../../../../../assets/img/logogallery/logo-featured-28.jpg";
import logo29 from "../../../../../assets/img/logogallery/logo-featured-29.jpg";
import logo30 from "../../../../../assets/img/logogallery/logo-featured-30.jpg";
import logo31 from "../../../../../assets/img/logogallery/logo-featured-31.jpg";
import logo32 from "../../../../../assets/img/logogallery/logo-featured-32.jpg";
import logo33 from "../../../../../assets/img/logogallery/logo-featured-33.jpg";
import logo34 from "../../../../../assets/img/logogallery/logo-featured-34.jpg";
import logo35 from "../../../../../assets/img/logogallery/logo-featured-35.jpg";
import logo36 from "../../../../../assets/img/logogallery/logo-featured-36.jpg";

export default function GalarieDeLogos() {
  const buttonMargin = "15px auto 10px";
  const iconColor = "#538B23";
  return (
    <div className={styles.container}>
      <h1>Galerie de logos</h1>
      <h2 style={{ margin: "20px 0 30px", color: "black" }}>
        Des exemples de notre travail.
      </h2>

      <p>
        <strong>
          Vous êtes prêt?{" "}
          <Link
            className={styles.link}
            to="/demarrez/conception-de-logo/forfaits-et-tarifs/"
          >
            Cliquez sur le lien pour voir les forfaits et les prix dans le même
            onglet
          </Link>{" "}
          pour commencer la conception du logo.
        </strong>
      </p>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo1}
          alt="Matex Homes Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img className="img-responsive" src={logo2} alt="Dr Flowers Logo"></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo3}
          alt="The Pat Cartman Show Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo4}
          alt="Jason M. Harshbarger Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img className="img-responsive" src={logo5} alt="Hamblin Logo"></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo6}
          alt="Red Rat Systems Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo7}
          alt="Atlas Sign Industries Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo8}
          alt="Sierra Group Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo9}
          alt="Legal Support Professionals Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo10}
          alt="Mountain Airgear Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo11}
          alt="Peers Car Sales Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo12}
          alt="Adam's Apple Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img className="img-responsive" src={logo13} alt="Dwellings Logo"></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo14}
          alt="Raymond Learning Center Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo15}
          alt="Skyline Productions Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img className="img-responsive" src={logo16} alt="AccuTax Logo"></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo17}
          alt="Ortho Smile Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo18}
          alt="Techno Resources Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo19}
          alt="Fone Corderro Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img className="img-responsive" src={logo20} alt="Ligaly Logo"></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img className="img-responsive" src={logo21} alt="Nitelux Logo"></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img className="img-responsive" src={logo22} alt="Jesto Logo"></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo23}
          alt="Cyber Motor Cars Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo24}
          alt="Refine Home Designs Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img className="img-responsive" src={logo25} alt="Lancastor Logo"></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img className="img-responsive" src={logo26} alt="Star Fish Logo"></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo27}
          alt="Global Mystery Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img className="img-responsive" src={logo28} alt="Newport Logo"></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo29}
          alt="Cotton Wood Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img className="img-responsive" src={logo30} alt="DBS Logo"></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo31}
          alt="Cannes Art Gallery Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo32}
          alt="Portfolio Management Consulting Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img className="img-responsive" src={logo33} alt="Joemax Logo"></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img className="img-responsive" src={logo34} alt="Redline Logo"></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo35}
          alt="Contractor Crossing Logo"
        ></img>
      </div>

      <div className="col-md-2 col-sm-4 col-xs-6">
        <img
          className="img-responsive"
          src={logo36}
          alt="Check on the Kids Logo"
        ></img>
      </div>

      <div className="clearfix"></div>
    </div>
  );
}
